var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-breadcrumb",
        _vm._l(_vm.breadcrumbs, function (item, index) {
          return _c(
            "b-breadcrumb-item",
            {
              key: index,
              attrs: { href: "#", active: item.active },
              on: {
                click: function ($event) {
                  return _vm.selectedFolder(item.folder_uuid)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottom",
                      modifiers: { hover: true, bottom: true },
                    },
                  ],
                  attrs: { title: item.text },
                },
                [_vm._v(_vm._s(_vm.Truncate(item.text, 35)))]
              ),
            ]
          )
        }),
        1
      ),
      _c("b-table", {
        key: "inbox_" + _vm.redrawIndex,
        staticClass: "ml-0 pl-0",
        attrs: {
          small: "",
          bordered: "",
          items: _vm.items,
          hover: "",
          fields: _vm.fields,
          "no-local-sorting": "",
          "sort-icon-left": "",
          "sort-by": _vm.tableSortBy,
          "sort-desc": _vm.tableSortDesc,
          "tbody-tr-class": _vm.rowClass,
          busy: _vm.loading,
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.tableSortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.tableSortBy = $event
          },
          "update:sortDesc": function ($event) {
            _vm.tableSortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.tableSortDesc = $event
          },
          "row-clicked": _vm.doubleClickedRow,
          "sort-changed": _vm.sortingChanged,
        },
        scopedSlots: _vm._u([
          {
            key: "head(size3)",
            fn: function () {
              return [
                _vm.items.length != 0
                  ? _c("b-form-checkbox", {
                      on: { change: _vm.addAll },
                      model: {
                        value: _vm.add_all,
                        callback: function ($$v) {
                          _vm.add_all = $$v
                        },
                        expression: "add_all",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(size3)",
            fn: function (data) {
              return [
                data.item.type == "folder" &&
                data.item.name != ".." &&
                _vm.IsAdmin(data.item)
                  ? _c("b-form-checkbox", {
                      attrs: {
                        name: "check-button",
                        value: data.item.folder_uuid,
                      },
                      on: { change: _vm.checkAllSelected },
                      model: {
                        value: _vm.selectedFolders,
                        callback: function ($$v) {
                          _vm.selectedFolders = $$v
                        },
                        expression: "selectedFolders",
                      },
                    })
                  : _vm._e(),
                data.item.type == "file" &&
                data.item.name != ".." &&
                _vm.IsAdmin(data.item)
                  ? _c("b-form-checkbox", {
                      attrs: {
                        name: "check-button",
                        value: data.item.file_uuid,
                      },
                      on: { change: _vm.checkAllSelected },
                      model: {
                        value: _vm.selectedFiles,
                        callback: function ($$v) {
                          _vm.selectedFiles = $$v
                        },
                        expression: "selectedFiles",
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(modified_date)",
            fn: function (data) {
              return [
                data.item.name != ".."
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.FormatDateTime(data.item.created_date))
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(files)",
            fn: function (data) {
              return [
                data.item.name != ".."
                  ? _c("i", { class: _vm.iconClass(data.item) })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                data.item.name != ".."
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: { title: data.item.name },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.Truncate(data.item.name, 35)) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                data.item.name == ".."
                  ? _c("div", [
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "fa-light fa-arrow-left",
                        attrs: { title: _vm.$t("BACK") },
                      }),
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("BACK")) + "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(created_by)",
            fn: function (data) {
              return [
                !data.item.created_by &&
                data.item.type == "file" &&
                data.item.created_by_name
                  ? _c("span", [_vm._v(_vm._s(data.item.created_by_name))])
                  : _vm._e(),
                data.item.created_by && data.item.type == "file"
                  ? _c("UserInfo", {
                      attrs: { user_uuid: data.item.created_by },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(size2)",
            fn: function (data) {
              return [
                _vm.IsAdmin(data.item)
                  ? _c(
                      "b-dropdown",
                      {
                        attrs: {
                          "toggle-class": "no-border",
                          "no-caret": "",
                          target: "_blank",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("i", {
                                    staticClass: "fa-regular fa-ellipsis",
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        data.item.type == "folder"
                          ? _c(
                              "span",
                              [
                                _vm.section != "APPLICATION"
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.folderUpdate(
                                              data.item.folder_uuid
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("UPDATE")))]
                                    )
                                  : _vm._e(),
                                1 == 0
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.folderMove(
                                              data.item.folder_uuid
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("FILESTORAGE.MOVE_FOLDER")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.IsAdmin(data.item)
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.folderUsers(
                                              data.item.folder_uuid
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("FILESTORAGE.SHARES"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.is_admin
                                  ? _c("b-dropdown-divider")
                                  : _vm._e(),
                                _vm.is_admin
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#", variant: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.folderRemove(
                                              data.item.folder_uuid
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        data.item.type == "file"
                          ? _c(
                              "span",
                              [
                                _vm.section == "FILES"
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileUpdate(
                                              data.item.file_uuid,
                                              data.item.name
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                                    )
                                  : _vm._e(),
                                _vm.section == "APPLICATION"
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileInfo(
                                              data.item.file_uuid
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                                    )
                                  : _vm._e(),
                                _c("b-dropdown-divider"),
                                _vm.section != "APPLICATION"
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileNewVersion(
                                              data.item.file_uuid
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("UPDATE")))]
                                    )
                                  : _vm._e(),
                                1 == 0
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileMove(
                                              data.item.file_uuid
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("FILESTORAGE.MOVE_FILE")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileSendAs(data.item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("SEND_AS")))]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileDownload(
                                          data.item.file_uuid
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("DOWNLOAD")))]
                                ),
                                _vm.is_admin
                                  ? _c("b-dropdown-divider")
                                  : _vm._e(),
                                _vm.is_admin
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#", variant: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileRemove(
                                              data.item.file_uuid
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                data.item.name != ".." ? _c("span") : _vm._e(),
              ]
            },
          },
          {
            key: "cell(shared)",
            fn: function (data) {
              return [
                data.item.type == "folder" && data.item.name != ".."
                  ? _c("span", [
                      data.item.access_items
                        ? _c("span", [
                            data.item.access_items.length > 1
                              ? _c("span", [_vm._v(_vm._s(_vm.$t("YES")))])
                              : _vm._e(),
                            data.item.access_items.length == 1
                              ? _c("span", [_vm._v(_vm._s(_vm.$t("NO")))])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      !data.item.access_items
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("NO")))])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                data.item.type == "file" ? _c("span", [_vm._v("-")]) : _vm._e(),
              ]
            },
          },
          {
            key: "cell(size)",
            fn: function (data) {
              return [
                data.item.name != ".."
                  ? _c("span", [
                      data.item.type == "folder"
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  data.item.childFolders + data.item.files
                                ) +
                                "\n          " +
                                _vm._s(_vm.$t("FILESTORAGE.ITEMS")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      data.item.type == "file"
                        ? _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.bytesToSize(data.item.size)) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(id)",
            fn: function (data) {
              return [
                _c("i", { class: _vm.iconClass(data.item) }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "pl-2",
                    attrs: { title: data.item.name },
                  },
                  [_vm._v(_vm._s(_vm.Truncate(data.item.name, 35)))]
                ),
                data.item.name != ".."
                  ? _c("div", [
                      data.item.type == "folder"
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  data.item.childFolders + data.item.files
                                ) +
                                "\n          " +
                                _vm._s(_vm.$t("FILESTORAGE.ITEMS")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      data.item.type == "file"
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.bytesToSize(data.item.size)) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                data.item.type == "file"
                  ? _c(
                      "p",
                      { staticClass: "mt-1" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("MODIFIED_BY")) +
                            "\n        "
                        ),
                        !_vm.created_by
                          ? _c("span", [_vm._v(_vm._s(_vm.created_by_name))])
                          : _vm._e(),
                        _vm.created_by
                          ? _c("UserInfo", {
                              attrs: {
                                user_uuid: data.item.created_by,
                                "name-only": "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.FormatDateTime(data.item.created_date)) +
                            "\n      "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.showBulk
        ? _c(
            "div",
            [
              !_vm.move && (_vm.is_admin || _vm.folder_uuid == "")
                ? _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "danger" },
                      on: { click: _vm.removeSelected },
                    },
                    [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  attrs: { variant: "dark", disabled: !_vm.showSendAs },
                  on: { click: _vm.fileSendAsSelected },
                },
                [_vm._v(_vm._s(_vm.$t("SEND_AS_MESSAGE")))]
              ),
              this.selectedFolders.length
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("CANNOT_SEND_FOLDER_IN_MESSAGE"))),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }