var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        [
          _c("div", { staticClass: "mt-2 clearfix" }, [
            _c("dl", [
              _c("dt", [_vm._v(_vm._s(_vm.$t("NAME")))]),
              _c("dd", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    attrs: { title: _vm.information.name },
                  },
                  [_vm._v(_vm._s(_vm.Truncate(_vm.information.name, 55)))]
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("VERSION")))]),
              _c("dd", [_vm._v(_vm._s(_vm.currentVersion.version))]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("EDITED_BY")))]),
              _c(
                "dd",
                [
                  !_vm.information.created_by
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.information.created_by_name)),
                      ])
                    : _vm._e(),
                  _vm.information.created_by
                    ? _c("UserInfo", {
                        attrs: {
                          "name-only": "",
                          user_uuid: _vm.information.created_by,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("dd"),
              _c("dt", [_vm._v(_vm._s(_vm.$t("CREATED")))]),
              _c("dd", [
                _vm._v(
                  _vm._s(_vm.FormatDateTime(_vm.currentVersion.created_date))
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("UUID")))]),
              _c("dd", [_vm._v(_vm._s(_vm.information.file_uuid))]),
              _vm.folder_information
                ? _c("dt", [_vm._v(_vm._s(_vm.$t("FILESTORAGE.FOLDER")))])
                : _vm._e(),
              _vm.folder_information
                ? _c("dd", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: { title: _vm.folder_information.name },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Truncate(_vm.folder_information.name, 55))
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("dt", [_vm._v(_vm._s(_vm.$t("SIZE")))]),
              _c("dd", [
                _vm._v(_vm._s(_vm.bytesToSize(_vm.currentVersion.size))),
              ]),
            ]),
          ]),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c("b-button", { on: { click: _vm.closeBtn } }, [
            _vm._v(_vm._s(_vm.$t("CLOSE"))),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }